<template>
  <section class="zhulifei">
    <imageBanner :imgBannerObj="imgBannerObj" />
    <div class="container">
      <section class="title">
        <el-image :src="titleObj.imgUrl" style="width: 8rem;"></el-image>
        <h1><strong>{{ titleObj.title }}</strong></h1>
        <p v-for="(item, index) in titleObj.content" :key="index">{{ item }}</p>
      </section>
      <el-row :gutter="30" v-for="(org, i) in zhulifeiData" :key="i" style="margin-bottom: 2rem;">
        <el-col :span="12" v-for="(item, index) in org" :key="index" class="details">
          <div class="content">
            <el-image :src="item.imgUrl"></el-image>
            <p><strong>{{ item.title }}</strong></p>
            <p>{{ item.en }}</p>
            <p><router-link class="link" :to="{path: item.url, query: {id: item.id}}">更多 <i class="el-icon-right"></i></router-link></p>
          </div>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
import imageBanner from '../../components/ImageBanner.vue'
export default {
  name: 'zhulifei',
  components: {
    imageBanner,
  },
  data () {
    return {
      imgBannerObj: {
        url: require('../../assets/images/aboutus/05-助力飞.png'),
        slogan: require('../../assets/images/common/slogan.png'),
      },
      titleObj: {
        title: '助力飞现代智慧供应链',
        content: [
          '致力于打造由原厂集中供货的企业版拼多多；',
          '以构建共享库存平台优化赋能企业的个性化采销需求，通过一体化系统定制多维度',
          '实现企业的降本增效和收益增长。',
        ],
        imgUrl: require('../../assets/images/2023/zhulifei/00.png'),
      },
      zhulifeiData: [
        [
          {imgUrl: require('../../assets/images/2023/zhulifei/首页图_1.png'), title: '助力飞的结构特点', en: 'Zhulifei Structural Features', url: '/zhulifeiDetails', id: 1},
          {imgUrl: require('../../assets/images/2023/zhulifei/首页图_2.png'), title: '为什么是我们？', en: 'Why Reglory?', url: '/whyReglory', id: 2},
        ],
        [
          {imgUrl: require('../../assets/images/2023/zhulifei/首页图_3.png'), title: '助力飞核心亮点', en: 'Core Highlights', url: '/coreHighlights', id: 3},
          {imgUrl: require('../../assets/images/2023/zhulifei/首页图_4.png'), title: '构建多赢体系', en: 'Build An All-win System', url: '/buildSystem', id: 4},
        ]
      ]
    }
  }
}
</script>

<style scoped>
.zhulifei {background-color: #ecf0f5; padding-bottom: 1rem;}
.container p {margin: 1rem 0; font-size: 2rem; color: #666;}
.container .title {text-align: center; line-height: 2; padding: 1rem 0;}
.container .title  strong { font-size: 2rem; border-bottom: 4px solid #ccc; padding: .6rem 0; line-height: 2;}
.container .title p {font-size: 1.4rem;}
.content {text-align: center; line-height: 2; font-size: 2rem; color: #666; padding-bottom: 1rem; border: 1px solid #ccc;}
.content strong { font-size: 2rem; border-bottom: 4px solid #e67700; padding: .6rem 0; line-height: 2;}
.content .el-image {width: 100%;}
.content .link {color: #eee; display: inline-block; padding: 0 2rem; background-color: #e67700; border-radius: 2rem; font-size: 1.2rem;}
</style>